import { orgAPI } from '@/common/ky';
import { AuthProvider } from '@/common/types/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { PricingKey } from '../pricing/tiers';
import {
  createWebhookEndpoint,
  CreateOrUpdateWebhookEndpointBody,
  deleteProject,
  getAllWebhookEndpoints,
  getProject,
  getProjectConfig,
  GetProjectResponse,
  getSubscription,
  getWebhookEndpoint,
  ProjectConfig,
  updateWebhookEndpoint,
  WebhookEndpoint,
  WebhookEvent,
  PID,
  deleteWebhookEndpoint,
  updateProject,
  Billing,
} from './api';

export interface OrgEntity {
  Name: string;
  FriendlyName: string;
  OID: string;
  Admin: string;
  StripeCID: string;
}

export interface GetOrgResponse extends OrgEntity {}

export function useGetOrg(oid?: string) {
  return useQuery(['org', oid], () => getOrg(oid!), { enabled: !!oid });
}

interface GetOrgsResponse {
  OIDs: string[];
}

export function useGetOrgs() {
  return useQuery('orgs', async () => {
    const orgsResponse = await orgAPI.get('admin/org').json<GetOrgsResponse>();
    return Promise.all(orgsResponse.OIDs.map((oid) => getOrg(oid)));
  });
}

function getOrg(oid: string) {
  return orgAPI.get(`admin/org/${oid}`).json<GetOrgResponse>();
}

export function useGetProjects(oid?: string) {
  return useQuery(
    ['projects', oid],
    async () => {
      const projectsResponse = await orgAPI
        .get(`admin/org/${oid}/project`)
        .json<{ PIDs: string[] }>();

      if (!projectsResponse.PIDs?.length) {
        return [];
      }

      return Promise.all(projectsResponse.PIDs.map((pid) => getProject(pid)));
    },
    { enabled: !!oid }
  );
}

export function useDeleteProject(
  pid?: string,
  options?: Omit<UseMutationOptions, 'mutationFn'>
) {
  return useMutation(() => {
    if (!pid) {
      return Promise.reject('PID is undefined');
    }
    return deleteProject(pid);
  });
}

export function useGetProject(pid?: string) {
  return useQuery(
    ['project', pid],
    () => {
      if (!pid) {
        return Promise.reject('PID is undefined');
      }
      return getProject(pid);
    },
    { enabled: !!pid }
  );
}

export function useGetGooseLinkUserClaims(uid?: string) {
  return useQuery(
    ['userClaims', uid],
    () => orgAPI.get(`api/admin/user/${uid}/claims`).json(),
    { enabled: !!uid }
  );
}

interface CreateOrgRequest {
  Name: string;
}

interface CreateOrgResponse extends OrgEntity {}

export function useCreateOrg() {
  return useMutation<CreateOrgResponse, unknown, CreateOrgRequest>(
    (createOrgRequestBody) =>
      orgAPI.post('admin/org', { json: createOrgRequestBody }).json()
  );
}

interface CreateStripeCustomerRequest {
  OID: string;
}

interface CreateStripeCustomerResponse extends OrgEntity {}

export function useCreateStripeCustomer() {
  return useMutation<
    CreateStripeCustomerResponse,
    unknown,
    CreateStripeCustomerRequest
  >((createStripeCustomerRequestBody) =>
    orgAPI
      .post(
        `admin/org/${createStripeCustomerRequestBody.OID}/stripe/customer`,
        { json: createStripeCustomerRequestBody }
      )
      .json()
  );
}

interface CreateStripeSubscriptionRequest {
  OID: string;
  PID: string;
  PricingKey: PricingKey;
}

interface CreateStripeSubscriptionResponse extends GetProjectResponse {}

export function useCreateStripeSubscription(
  options?: Omit<
    UseMutationOptions<
      CreateStripeSubscriptionResponse,
      unknown,
      CreateStripeSubscriptionRequest
    >,
    'mutationFn'
  >
) {
  return useMutation<
    CreateStripeSubscriptionResponse,
    unknown,
    CreateStripeSubscriptionRequest
  >(
    (createStripeCustomerRequestBody) =>
      orgAPI
        .post(
          `admin/project/${createStripeCustomerRequestBody.PID}/stripe/subscription`,
          {
            json: createStripeCustomerRequestBody,
          }
        )
        .json(),
    options
  );
}

interface FirebaseAuthInfo {
  Project: string;
  APIKey: string;
}

interface SupabaseAuthConfig {
  PublicAPIKey: string;
  URL: string;
}

interface AuthProviderInfo {
  Provider: AuthProvider;
  Firebase?: FirebaseAuthInfo;
  Supabase?: SupabaseAuthConfig;
}

interface CreateProjectRequest {
  Name: string;
  Auth: AuthProviderInfo;
}

export interface UpdateProjectRequest {
  Auth?: AuthProviderInfo;
  Billing?: Billing;
}

interface CreateProjectResponse extends GetProjectResponse {}

export interface UpdateProjectResponse extends GetProjectResponse {}

export function useCreateProject(
  oid: string,
  options: Omit<
    UseMutationOptions<CreateProjectResponse, unknown, CreateProjectRequest>,
    'mutationFn'
  >
) {
  return useMutation<CreateProjectResponse, unknown, CreateProjectRequest>(
    (createProjectRequestBody) =>
      orgAPI
        .post(`admin/org/${oid}/project`, { json: createProjectRequestBody })
        .json(),
    options
  );
}

export function useUpdateProject(
  pid?: string,
  options?: Omit<
    UseMutationOptions<UpdateProjectResponse, unknown, UpdateProjectRequest>,
    'mutationFn'
  >
) {
  return useMutation<UpdateProjectResponse, unknown, UpdateProjectRequest>(
    (updateProjectRequestBody) => {
      if (!pid) {
        throw new Error('PID is missing');
      }
      return updateProject(pid, updateProjectRequestBody);
    },
    options
  );
}

interface CreateProjectConfigRequest extends ProjectConfig {}

interface CreateProjectConfigResponse extends ProjectConfig {}

export function useCreateProjectConfig(
  pid: PID,
  options: Omit<
    UseMutationOptions<
      CreateProjectConfigResponse,
      unknown,
      CreateProjectConfigRequest
    >,
    'mutationFn'
  >
) {
  return useMutation<
    CreateProjectConfigResponse,
    unknown,
    CreateProjectConfigRequest
  >(
    (createProjectConfigRequestBody) =>
      orgAPI
        .put(`admin/project/${pid}/config`, {
          json: createProjectConfigRequestBody,
        })
        .json(),
    options
  );
}

export function useGetProjectConfig(pid?: PID) {
  return useQuery(
    ['projectConfig', pid],
    () => {
      if (!pid) {
        return Promise.reject('PID is undefined');
      }
      return getProjectConfig(pid);
    },
    { enabled: !!pid }
  );
}

interface UpdateOrgRequest {
  Name: string;
}

export function useUpdateOrg(oid: string) {
  return useMutation<CreateOrgResponse, unknown, UpdateOrgRequest>(
    (updateOrgRequestBody) =>
      orgAPI.patch(`admin/org/${oid}`, { json: updateOrgRequestBody }).json()
  );
}

export function useGetSubscription(
  oid: string | undefined,
  pid: PID | undefined
) {
  return useQuery(
    ['subscription', oid, pid],
    () => {
      if (!pid) {
        return Promise.reject('PID is undefined');
      }
      return getSubscription(pid);
    },
    {
      enabled: !!pid && !!oid,
    }
  );
}

export function useGetWebhookEndpoints(pid: PID) {
  return useQuery('webhookEndpoint', () => getAllWebhookEndpoints(pid));
}

export function useGetWebhookEndpointByID(pid: PID, wid: WebhookEvent) {
  return useQuery(
    ['webhookEndpoint', wid],
    () => getWebhookEndpoint(pid, wid),
    { enabled: !!pid && !!wid }
  );
}

export function useCreateWebhookEndpoint(
  pid: PID,
  wid: WebhookEvent,
  options: Omit<
    UseMutationOptions<
      { WebhookEndpoint: WebhookEndpoint },
      unknown,
      CreateOrUpdateWebhookEndpointBody
    >,
    'mutationFn'
  >
) {
  return useMutation<
    { WebhookEndpoint: WebhookEndpoint },
    unknown,
    CreateOrUpdateWebhookEndpointBody
  >(
    (createWebhookEndpointBody) =>
      createWebhookEndpoint(pid, createWebhookEndpointBody),
    options
  );
}

export function useUpdateWebhookEndpoint(
  pid: PID,
  wid: WebhookEvent,
  options?: Omit<
    UseMutationOptions<
      { WebhookEndpoint: WebhookEndpoint },
      unknown,
      CreateOrUpdateWebhookEndpointBody
    >,
    'mutationFn'
  >
) {
  return useMutation<
    { WebhookEndpoint: WebhookEndpoint },
    unknown,
    CreateOrUpdateWebhookEndpointBody
  >(
    (createWebhookEndpointBody) =>
      updateWebhookEndpoint(pid, wid, createWebhookEndpointBody),
    options
  );
}

export function useDeleteWebhookEndpoint(
  pid: PID,
  wid: WebhookEvent,
  options?: UseMutationOptions
) {
  return useMutation(() => deleteWebhookEndpoint(pid, wid), options);
}

export function useProjectForm(project?: GetProjectResponse) {
  const [authProvider, setAuthProvider] = useState<AuthProvider>('Firebase');
  const form = useForm({
    defaultValues: {
      projectName: '',
      firebaseProjectName: '',
      firebaseWebApiKey: '',
      supabasePublicAPIKey: '',
      supabaseURL: '',
    },
  });
  const { reset } = form;

  useEffect(() => {
    if (project) {
      reset({
        projectName: project.Name,
        firebaseProjectName: project.Auth?.Firebase?.Project ?? '',
        firebaseWebApiKey: project.Auth?.Firebase?.APIKey ?? '',
        supabasePublicAPIKey: project.Auth?.Supabase?.PublicAPIKey ?? '',
        supabaseURL: project.Auth?.Supabase?.URL ?? '',
      });
    }
  }, [reset, project]);

  const onAuthProviderChange = (value: string) =>
    setAuthProvider(value as AuthProvider);

  return { ...form, authProvider, onAuthProviderChange };
}
