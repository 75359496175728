import { orgAPI } from '@/common/ky';
import { AuthProvider } from '@/common/types/auth';
import { PricingKey } from '../pricing/tiers';
import { UpdateProjectRequest, UpdateProjectResponse } from './hooks';
import { WebhookStatus } from './WebhooksList';

export enum LicenceType {
  Free,
  Startup,
  Business,
}

export enum ProjectStatus {
  Active,
  Deleted,
  Suspended,
}

export enum WebhookEvent {
  TargetOffline = 'TargetOffline',
  TargetDoesNotExist = 'TargetDoesNotExist',
  UserAuth = 'UserAuth',
}

export interface ProjectConfig {
  PermissiveGroups: string[];
  ChannelGroups: string[];
  AuthHookCacheDurationSeconds: number;
}

interface CreatePortalSessionRequest {
  OID: string;
  PID: string;
  ReturnURL: string;
}

interface CreatePortalSessionResponse {
  RedirectTo: string;
}

export type PID = string;

export function createPortalSession(
  pid: PID,
  requestBody: CreatePortalSessionRequest
) {
  return orgAPI
    .post(`admin/project/${pid}/stripe/portal-session`, {
      json: requestBody,
    })
    .json<CreatePortalSessionResponse>();
}

export interface GetProjectResponse {
  Auth: {
    Provider: AuthProvider;
    Okta: null;
    Supabase: {
      PublicAPIKey: string;
      URL: string;
    };
    Firebase: {
      Project: string;
      APIKey: string;
    };
  };
  Cert: string;
  Name: string;
  OID: string;
  PID: string;
  Secret: string;
  Billing: Billing;
  Status: ProjectStatus;
}

export interface Billing {
  StripeSubID: string;
  LicenceType: LicenceType;
  UserQuota: number;
}

export interface WebhookEndpoint {
  WID: WebhookEvent;
  URL: string;
  Description: string;
  Secret: string;
  Status: WebhookStatus;
}

export interface CreateOrUpdateWebhookEndpointBody {
  WID: WebhookEvent;
  URL: string;
  Secret?: string;
  Description?: string;
  Status?: WebhookStatus;
}

export async function getProject(pid: PID) {
  const project = await orgAPI
    .get(`admin/project/${pid}`)
    .json<GetProjectResponse>();

  return project;
}

export async function getProjectConfig(pid: PID) {
  return orgAPI.get(`admin/project/${pid}/config`).json<ProjectConfig>();
}

export async function deleteProject(pid: PID) {
  return orgAPI.delete(`admin/project/${pid}`).json();
}

export async function updateProject(
  pid: PID,
  body: UpdateProjectRequest
): Promise<UpdateProjectResponse> {
  return orgAPI.patch(`admin/project/${pid}`, { json: body }).json();
}

export interface GetSubscriptionResponse {
  SubID: string;
  PriceID: string;
  ProductID: string;
  Currency: string;
  UnitAmount: number;
  UnitAmountDecimal: number;
  Type: string;
  PriceLookupKey: PricingKey;
  Recurring: {
    AggregateUsage: string;
    Interval: string;
    IntervalCount: number;
    TrialPeriodDays: number;
    UsageType: string;
  };
  CurrentPeriodStart: number;
  CurrentPeriodEnd: number;
}

export function getSubscription(pid: PID) {
  return orgAPI
    .get(`admin/project/${pid}/stripe/subscription`)
    .json<GetSubscriptionResponse>();
}

export function getAllWebhookEndpoints(pid: PID) {
  return orgAPI
    .get(`admin/project/${pid}/webhook`)
    .json<{ WebhookEndpoints: WebhookEndpoint[] }>();
}

export function getWebhookEndpoint(pid: PID, wid: WebhookEvent) {
  return orgAPI
    .get(`admin/project/${pid}/webhook/${wid}`)
    .json<WebhookEndpoint>();
}

export function createWebhookEndpoint(
  pid: PID,
  requestBody: CreateOrUpdateWebhookEndpointBody
) {
  return orgAPI
    .post(`admin/project/${pid}/webhook`, {
      json: requestBody,
    })
    .json<{ WebhookEndpoint: WebhookEndpoint }>();
}

export function updateWebhookEndpoint(
  pid: PID,
  wid: WebhookEvent,
  requestBody: CreateOrUpdateWebhookEndpointBody
) {
  return orgAPI
    .put(`admin/project/${pid}/webhook/${wid}`, {
      json: requestBody,
    })
    .json<{ WebhookEndpoint: WebhookEndpoint }>();
}

export function deleteWebhookEndpoint(pid: PID, wid: WebhookEvent) {
  return orgAPI.delete(`admin/project/${pid}/webhook/${wid}`);
}
