import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GetOrgResponse, useGetOrgs, useGetProjects } from './hooks';

const CurrentOrgContext = createContext<
  | readonly [
      GetOrgResponse | undefined,
      Dispatch<SetStateAction<GetOrgResponse | undefined>>
    ]
  | undefined
>(undefined);

export function CurrentOrgProvider(props: PropsWithChildren<{}>) {
  const [currentOrg, setCurrentOrg] = useState<GetOrgResponse | undefined>();
  const { data: orgs } = useGetOrgs();

  const router = useRouter();

  const currentOrgState = useMemo(
    () => [currentOrg, setCurrentOrg] as const,
    [currentOrg, setCurrentOrg]
  );

  useEffect(() => {
    if (!currentOrg && orgs) {
      const oidInUrl =
        router.query.oid ??
        router.query.org ??
        (router.query.pid as string)?.split('_')[0];
      const lastKnownCurrentOrg = orgs.find((org) => org.OID === oidInUrl);
      setCurrentOrg(lastKnownCurrentOrg ?? orgs[0]);
    }
  }, [
    currentOrg,
    orgs,
    router.query.oid,
    router.query.org,
    router.query.pid,
    setCurrentOrg,
  ]);

  return (
    <CurrentOrgContext.Provider value={currentOrgState}>
      {props.children}
    </CurrentOrgContext.Provider>
  );
}

export function useCurrentOrg() {
  const context = useContext(CurrentOrgContext);
  if (!context) {
    throw new Error('useCurrentOrg must be used within CurrentOrgProvider');
  }

  return context;
}
