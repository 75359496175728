import ky from 'ky';
import { getIDToken } from '@/modules/auth/firebase';

export const authenticatedAPI = ky.extend({
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await getIDToken();

        if (!token) {
          throw new Error('no token from current user');
        }
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});

export const orgAPI = authenticatedAPI.extend({
  prefixUrl: `https://org.${process.env.NEXT_PUBLIC_ENV}.gooselink.com`,
});

export const cloudStorageAPI = ky.create({
  prefixUrl: `https://storage.googleapis.com/storage/v1`,
});
