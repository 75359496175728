import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';

import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@/modules/theme';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  QueryClient,
  QueryClientProvider,
  QueryClientConfig,
} from 'react-query';
import { CurrentOrgProvider } from '@/modules/admin/CurrentOrgContext';
import { isDev } from '@/common/utils';

const devQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
};

const queryClient = new QueryClient(isDev() ? devQueryConfig : undefined);

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
};

firebase.initializeApp(firebaseConfig);

function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrentOrgProvider>
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </CurrentOrgProvider>
    </QueryClientProvider>
  );
}

export default App;
