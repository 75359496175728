import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme(
  {
    styles: {
      global: (props: any) => ({
        body: {
          bg: mode('white', 'gray.700')(props),
        },
      }),
    },
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
    fonts: {
      heading: 'Poppins',
      body: 'Poppins',
    },
    colors: {
      primary: {
        50: '#fff9dc',
        100: '#ffeeaf',
        200: '#fde280',
        300: '#fdda5f',
        400: '#fccb20',
        500: '#e3b109',
        600: '#b08a02',
        700: '#7e6200',
        800: '#4d3b00',
        900: '#1b1400',
      },
      secondary: {
        50: '#f0f7ff',
        100: '#b7d1fb',
        200: '#83aef3',
        300: '#5e95ee',
        400: '#3376e9',
        500: '#1c5dcf',
        600: '#1448a2',
        700: '#0b3474',
        800: '#031f48',
        900: '#000a1c',
      },
      lightAccent: {
        50: '#e0fcfc',
        100: '#bfeeed',
        200: '#9ce0e0',
        300: '#6ed1d0',
        400: '#55c8c7',
        500: '#3baead',
        600: '#2b8887',
        700: '#1b6160',
        800: '#073b3b',
        900: '#001515',
      },
      darkAccent: {
        50: '#e9f0ff',
        100: '#c9d2eb',
        200: '#a6b4d8',
        300: '#8496c8',
        400: '#6178b8',
        500: '#475e9e',
        600: '#37497b',
        700: '#243153',
        800: '#161f37',
        900: '#060a18',
      },
      gray: {
        50: '#ebf3fb',
        100: '#d3d8df',
        200: '#b9bdc5',
        300: '#9fa3ad',
        400: '#8c8e99',
        500: '#696f7b',
        600: '#525760',
        700: '#3b3f47',
        800: '#22252c',
        900: '#0b0b15',
      },
      black: '#2C2E33',
    },
    components: {
      Button: {
        variants: {
          solid: {
            bg: 'primary.300',
            fontSize: '14px',
            fontWeight: 'semibold',
            lineHeight: '16px',
          },
          link: {
            color: 'primary.300',
            fontSize: 'md',
            fontWeight: 'bold',
            letterSpacing: '0.15px',
            lineHeight: '24px',
          },
        },
      },
      Heading: {
        variants: {
          headline1: {
            fontSize: '7xl',
            fontWeight: 300,
            letterSpacing: '-1.5px',
            lineHeight: '112px',
          },
          headline2: {
            fontSize: '6xl',
            fontWeight: 'normal',
            letterSpacing: '-0.5px',
            lineHeight: '72px',
          },
          headline3: {
            fontSize: '5xl',
            fontWeight: 'semibold',
            lineHeight: '56px',
          },
          headline4: {
            fontSize: '4xl',
            fontWeight: 'semibold',
            lineHeight: '48px',
          },
          headline5: {
            fontSize: '2xl',
            fontWeight: 'bold',
            letterSpacing: '0.18px',
            lineHeight: '40px',
          },
          headline6: {
            fontSize: 'xl',
            fontWeight: 'semibold',
            letterSpacing: '0.15px',
            lineHeight: '32px',
          },
          subtitle1: {
            fontSize: 'md',
            fontWeight: 'bold',
            letterSpacing: '0.15px',
            lineHeight: '24px',
          },
          subtitle2: {
            fontSize: 'sm',
            fontWeight: 'semibold',
            letterSpacing: '0.1px',
            lineHeight: '24px',
          },
        },
      },
      Text: {
        variants: {
          body1: {
            fontSize: 'md',
            fontWeight: 'normal',
            letterSpacing: '0.5px',
            lineHeight: '24px',
          },
          body2: {
            fontSize: 'sm',
            fontWeight: 'regular',
            letterSpacing: '0.25px',
            lineHeight: '24px',
          },
          button: {
            fontSize: 'sm',
            fontWeight: 'semibold',
            lineHeight: '16px',
          },
          overline: {
            fontSize: 'xs',
            fontWeight: 'semibold',
            letterSpacing: '2px',
            lineHeight: '16px',
          },
          caption: {
            fontSize: 'xs',
            fontWeight: 'medium',
            letterSpacing: '0.4px',
            lineHeight: '16px',
          },
          headerLinkDisabled: {
            display: 'inline-block',
            fontSize: 'sm',
            fontWeight: 'semibold',
            letterSpacing: '0.1px',
            lineHeight: '24px',
            color: 'gray.300',
          },
        },
      },
      Link: {
        variants: {
          header: {
            fontSize: 'sm',
            fontWeight: 'semibold',
            letterSpacing: '0.1px',
            lineHeight: '24px',
          },
          drawer: {
            fontSize: '2xl',
            fontWeight: 'bold',
            letterSpacing: '0.18px',
            lineHeight: '40px',
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
    components: ['Button', 'IconButton'],
  })
);

export default theme;
